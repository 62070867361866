import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Menu from "../components/menu"
import { graphql } from "gatsby"
import Projects from "../components/projects"
import { AnimatePresence, useScroll } from "framer-motion"
import useMeasure from "react-use-measure"
import Password from "../components/password"

const IndexPage = ({ data }) => {
  const { scrollYProgress } = useScroll();
  const [ref,{height}] = useMeasure()
  return (
    <Layout>

        <Password />
        <div className="md:!h-auto" style={{ height: `${(data.render.renders.length * 100)}vh` }}>
          <div ref={ref} className="w-screen flex md:flex md:flex-col h-screen  overflow-hidden fixed top-0 md:pt-0 md:relative md:h-auto" >
            <Menu />
            <Projects height={height} scroll={scrollYProgress} images={data.render.renders} />
          </div>
        </div>

    </Layout>
  )
}



export default IndexPage
export const Head = () => <Seo title="Home" />

export const query = graphql`
query IndexPage {
  render:datoCmsRender {
    renders{
      width
      height
      url
      gatsbyImageData(layout:FULL_WIDTH)
    }
  }
}

`