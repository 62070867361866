import React, { useLayoutEffect, useMemo, useState } from 'react'
import { motion, useTransform } from 'framer-motion'
import { GatsbyImage } from 'gatsby-plugin-image';
import useMeasure from 'react-use-measure'

const windowWidth = typeof window !== 'undefined' && window.innerWidth


export default function Projects({ images, scroll, height }) {
    const [ref, { width: refWidth, }] = useMeasure()

    const input = [0, 1]
    const output = useMemo(() => {
        return [`translateX(0%)`, `translateX(-${(100 - ((windowWidth / refWidth) * 100))}%)`]
    }, [refWidth])

    const transform = useTransform(scroll, input, output)


    return (
        <>
            <div className='relative h-screen md:h-full w-full md:hidden'>
                <div ref={ref} className='absolute h-full' >
                    <motion.div style={{ transform }} className='flex h-full pl-[200px]  pt-40 pb-40 will-change'>
                        {images.map((render) => {
                            return (
                                <Slide height={height} render={render} />
                            )
                        })}
                    </motion.div>
                </div>
            </div>
            <div className='hidden md:block space-y-[10px]'>
                {images.map((render) => {
                    return (
                        <GatsbyImage className='' image={render.gatsbyImageData} />
                    )
                })}
            </div>
        </>
    )
}

function Slide({ render,height }) {
    
    const width = useMemo(() => {
        const heightRatio = height / render.height
        return render.width * heightRatio
    }, [render,height])


    return (
        <div style={{ width }} className='h-full'>
            <GatsbyImage className='mr-40 h-full' image={render.gatsbyImageData} />
        </div>
    )
}
