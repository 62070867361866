import React, { useEffect, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

export default function Password() {
    const [open, setOpen] = useState(true)
    const ref = useRef(null)


    useEffect(() => {
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Enter') {
                submit()
            }
        })
    }, [])

    function submit() {
        console.log(ref.current.value)
        if (ref.current.value === 'Tilak Raj Kohli' ||ref.current.value === 'password123') {
            console.group('fun')
            setOpen(false)
        }
    }

    return (
        <AnimatePresence>
            {open ? <motion.div
                initial={{ opacity: 1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                key={open}
                className='h-full flex flex-col justify-center items-center fixed top-0 w-screen bg-white z-[200] p-20'>
                <div className='max-w-[250px] justify-center flex  flex-col items-center w-full'>
                    <svg className='mb-40  w-full max-w-[150px]' viewBox="0 0 154 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.2106 3.63864V0H0V3.63864H10.5261V23.3263H14.6845V3.63864H25.2106Z" fill="black" />
                        <path d="M69.369 14.2946H78.4981L84.1835 23.3263H88.9267L82.884 13.9373C86.2302 13.0601 88.3419 10.7535 88.3419 7.14732C88.3419 2.43659 84.8332 0 79.7326 0H65.2106V23.3263H69.369V14.2946ZM69.369 10.721V3.57366H79.5052C82.4941 3.57366 84.2484 4.84069 84.2484 7.14732C84.2484 9.42147 82.4941 10.721 79.5052 10.721H69.369Z" fill="black" />
                        <path d="M133.085 0H128.927V23.3263H133.085V16.0165L137.601 12.4104L148.159 23.3263H153.747L140.622 9.97376L153 0H147.087L133.085 11.6956V0Z" fill="black" />
                    </svg>
                    <input type='password' ref={ref} className='input  mb-[10px] w-full' placeholder='password' />
                    <button onClick={submit} className='submit-button' >
                        Submit
                    </button>
                </div>
            </motion.div>
                : null}
        </AnimatePresence>
    )
}
